import { get, getMany, set, setMany, clear } from "idb-keyval"

type ValidKeys =
  | "countDownDate"
  | "requestId"
  | "esiaData"
  | "birthDate"
  | "choice"
  | "id"
  | "geoData"
  | "name"
  | "phone"
  | "workAddress"
  | "registrationAddress"
  | "lifeAddress"
  | "theme"
  | "region"
  | "apiId"

export function getIDBValue<T>(key: ValidKeys) {
  return get<T>(key)
}

export function getManyIDBValues(keys: ValidKeys[]) {
  return getMany(keys)
}

export function setIDBValue(key: ValidKeys, data: any) {
  return set(key, data)
}

export function setManyIDBValues(entries: [ValidKeys, any][]) {
  return setMany(entries)
}

export function clearIDBData() {
  clear()
}
