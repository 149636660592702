/**
 * DataLayer - scrollEvent event
 * @description Срабатывает при обнаружения блока в области видимости пользователя , автоматически срабатывает на все ID которые указаны в хуке useDLScrollObserver
 * @description Для этого необходимо вставить компонент <WatcherDL/> в глобальную обертку страниц
 *
 * @example DLScrollEvent({visibleSection: 'formPkw', eventLabel?: globalThis?.location?.href})
 *
 * @default event: "scrollEvent"
 * @default name: ID блока\секции которую он обнаружил
 * @param eventLabel: Страница на которой произошло событие
 */

import { pushToDL } from "../utils/helper"
import type { TDLLandingsSections, TDLPageURL } from "./type"

type TDLScrollEvent = {
  visibleSection: TDLLandingsSections
  eventLabel?: TDLPageURL
}

export const DLScrollEvent = ({
  visibleSection,
  eventLabel = globalThis?.location?.href,
}: TDLScrollEvent) => {
  pushToDL({ event: "scrollEvent", name: visibleSection, eventLabel })
}
