import React, { useEffect } from "react"

import ThemeProvider from "@material-ui/styles/ThemeProvider"
import { redTheme } from "@ecom/ui/styles/themes/red"

import { SEO, SEOProps } from "../seo"
import NoIndex from "../NoIndex"
import { WatcherDL } from "../../helpers/WatcherDL"

export interface BaseLayoutProps extends SEOProps {
  children: React.ReactNode
  theme?: any
  noIndex?: boolean
}

export default function Layout({
  children,
  title,
  description,
  titleH1,
  url,
  shortTitle,
  theme = redTheme,
  noIndex = false,
  favicon,
}: BaseLayoutProps) {
  /* Нужно для событий dadata в dataLayer, отправка событий происходит в ui */
  useEffect(() => localStorage.setItem("dataLayerEventAction", "order"), [])

  return (
    <ThemeProvider theme={theme}>
      {noIndex && <NoIndex />}
      <SEO
        title={title}
        description={description}
        titleH1={titleH1}
        url={url}
        shortTitle={shortTitle}
        themeColor={theme?.palette?.primary?.main || undefined}
        favicon={favicon}
      />
      <WatcherDL />
      {children}
    </ThemeProvider>
  )
}
