import React from "react"
import { useDLScrollObserver } from "./utils/hooks"

export const WatcherDL = () => {
  // Необходимо указать ВСЕ компоненты и задать им нужный ID на родителя компонента, подробнее в DLScrollEvent
  useDLScrollObserver({
    ids: ["footer", "benefits", "calculator", "formPkw", "form", "calculatorRefin"],
  })

  return <></>
}
