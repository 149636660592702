import { useState, useEffect } from "react"
import { DLScrollEvent } from "../DLEvents/DLScrollEvent"
import type { TDLLandingsSections, TDLPageURL } from "../DLEvents/type"

const defaultOptions: IntersectionObserverInit = {
  threshold: 0.5,
}

type TDLObserverProps = {
  ids: TDLLandingsSections[]
  options?: IntersectionObserverInit
  eventLabel?: TDLPageURL
}

export const useDLScrollObserver = ({
  ids,
  options = defaultOptions,
  eventLabel,
}: TDLObserverProps) => {
  const [currentVisibleIDs] = useState(new Set())

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries
        .filter((entry) => entry.isIntersecting)
        .forEach((entry) => {
          const sectionId = entry.target.id
          if (!currentVisibleIDs.has(sectionId)) {
            currentVisibleIDs.add(sectionId)
            DLScrollEvent({
              visibleSection: sectionId as TDLLandingsSections,
              eventLabel: eventLabel ?? globalThis?.location?.href,
            })
          }
        })
    }, options)

    ids.forEach((id) => {
      const element = document.getElementById(id)
      if (element) {
        observer.observe(element)
      }
    })

    return () => {
      ids.forEach((id) => {
        const element = document.getElementById(id)
        if (element) {
          observer.unobserve(element)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVisibleIDs, ids])
}
